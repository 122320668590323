// socket.js
import io from "socket.io-client"

const SERVER = process.env.REACT_APP_SERVER_URL
// const SERVER = "http://192.168.4.227:8080"

let socket

function connectSocket() {
  if (!socket) {
    socket = io(SERVER)

    socket.on("connect_error", err => {
      console.error("Socket connection error:", err.message)
    })
  }

  return socket
}

export { connectSocket }
