import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import axios from "axios"

import { connectSocket } from "components/Socket/socket"
import { useNavigate } from "react-router-dom" // Import useNavigate
import Cookies from "js-cookie"
import store from "store"

const Login = props => {
  const [socket, setSocket] = useState(null)
  const navigate = useNavigate()
  //meta title
  document.title = "Login Page"
  // document.title = "Login | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "fahmi" || "",
      phone: "6289655355422" || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      phone: Yup.string().required("Please Enter Your Phone"),
    }),
    onSubmit: async values => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/auth/login",
          {
            username: values.username,
            phone_number: values.phone,
          }
        )

        // Handle success response

        const data = response.data
        console.log("Response:", data)
        // Store the token in cookies or local storage
        const user = data.user
        if (user) {
          // Option 1: Store token in cookies (using js-cookie)
          localStorage.setItem("auth_user", JSON.stringify(user))
        }
        const token = data.token
        if (token) {
          // Option 1: Store token in cookies (using js-cookie)
          Cookies.set("token", token, { expires: 1 }) // expires in 7 days
        }
        // Emit the response data through the socket
        if (socket) {
          socket.emit("user-login", data)
          console.log("Data sent through socket:", data)

          // Navigate to the chat-omni route
          window.location.href = "/chat-omni"
        }
      } catch (error) {
        // Handle error response
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        )
      }
    },
  })

  useEffect(() => {
    const socketInstance = connectSocket()
    setSocket(socketInstance)

    socketInstance.on("connection", () => {
      console.log("Socket connected")
    })

    socketInstance.on("connection_error", err => {
      console.error("Socket connection error:", err.message)
    })

    return () => {
      // Clean up the socket listeners if needed
      socketInstance.off("user-list")
      socketInstance.off("connection")
    }
  }, [])

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      error: login.error,
    })
  )

  const { error } = useSelector(LoginProperties)

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate))
  }

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type)
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Login Page</h5>
                        <p>Sign in to continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Username</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter Username"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Nomor Telepon</Label>
                        <Input
                          name="phone"
                          value={validation.values.phone || ""}
                          type="phone"
                          placeholder="Enter Phone"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.phone && validation.errors.phone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Aurora</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
